<template>
  <v-container
      id="panels"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">

        <base-material-card color="success" icon="mdi-card-account-details" title="Adding New User">
          <v-row>
            <v-col cols="10"></v-col>
            <v-col cols="2" class="text-right">
              <v-btn small right color="info" class="mr-0"
                     @click="$router.push({name: 'Users', params: {envCode: envSelected.value} })">
                <v-icon small class="mr-1">
                  mdi-arrow-left
                </v-icon>
                Back
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="organizations && organizations.length > 0" no-gutters>
            <v-col cols="12" md="12">
              <template v-if="loading">
                <v-progress-linear
                    indeterminate
                    color="primary"
                ></v-progress-linear>
                <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
              </template>
              <template v-else>
                <v-stepper v-model="step">
                  <v-stepper-header>
                    <v-stepper-step
                        :complete="step > 1"
                        step="1"
                    >
                      <div :class="{'purple--text': step === 1 , 'grey--text': step !== 1}">
                        <v-icon class="mr-1" :class="{'purple--text': step === 1 , 'grey--text': step !== 1}">
                          mdi-account
                        </v-icon>
                        <span>General</span>
                      </div>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        :complete="step > 2"
                        step="2"
                    >
                      <div :class="{'purple--text': step === 2 , 'grey--text': step !== 2}">
                        <v-icon class="mr-1" :class="{'purple--text': step === 2 , 'grey--text': step !== 2}">mdi-lock
                        </v-icon>
                        <span>Security</span>
                      </div>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3">
                      <div :class="{'purple--text': step === 3 , 'grey--text': step !== 3}">
                        <v-icon class="mr-1" :class="{'purple--text': step === 3 , 'grey--text': step !== 3}">mdi-apps
                        </v-icon>
                        <span>Multi Tenancy</span>
                      </div>
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <v-card
                          flat
                          class="mb-1"
                      >
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  label="First Name"
                                  v-model="user.firstName"
                                  prepend-icon="mdi-account"
                              />
                            </v-col>

                            <v-col cols="12" md="6">
                              <v-text-field
                                  label="Last Name"
                                  v-model="user.lastName"
                                  prepend-icon="mdi-account"
                              />
                            </v-col>

                            <v-col cols="12" md="6">
                              <v-text-field
                                  label="Email"
                                  type="email"
                                  v-model="user.email"
                                  prepend-icon="mdi-mail"
                              />
                            </v-col>

                            <v-col cols="12" md="6">
                              <v-select
                                  :items="organizations"
                                  v-model="user.company"
                                  item-text="name"
                                  item-value="id"
                                  label="Organization"
                                  hint="Select the Organization that the user belongs to."
                                  persistent-hint
                                  prepend-icon="mdi-domain"
                                  @change="setSecurityAndTenants"
                              ></v-select>
                            </v-col>

                            <v-col cols="12" md="6">
                              <v-text-field
                                  label="Password"
                                  type="password"
                                  v-model="user.password"
                                  prepend-icon="mdi-lock"
                              />
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  label="Confirm Password"
                                  type="password"
                                  v-model="user.confirmation"
                                  prepend-icon="mdi-lock"
                              />
                            </v-col>
                            <v-col cols="12" md="12">
                              <v-select
                                  :items="arw"
                                  v-model="user.auto_registration_flow"
                                  item-text="name"
                                  item-value="name"
                                  label="Auto Registration Flow"
                                  hint="Select the Auto Registration Flow"
                                  persistent-hint
                                  prepend-icon="mdi-domain"
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                      <v-card
                          class="mb-1"
                          flat
                      >
                        <v-card-text>
                          <user-account-security
                              ref="securityWrapper"
                              :fieldAccess="fieldAccess"
                              :groups="groups"
                              :strategies="strategies"
                              :context="user"
                              :env-selected="envSelected"
                              @updateThrottlingStrategies="updateThrottlingStrategies"
                          ></user-account-security>
                        </v-card-text>
                      </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                      <v-card
                          class="mb-2"
                          flat
                      >
                        <v-card-text>
                          <user-account-multitenancy
                              ref="multitenancyWrapper"
                              :fieldAccess="fieldAccess"
                              :section="'user'"
                              :context="user"
                              :env-selected="envSelected"
                              :tenants="tenants"
                          ></user-account-multitenancy>
                        </v-card-text>
                      </v-card>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </template>
            </v-col>
          </v-row>
          <v-row v-if="organizations && organizations.length > 0">
            <v-col cols="12" md="12" class="text-right pr-0 mr-0">

              <v-btn
                  color="secondary"
                  @click="step = 2"
                  v-if="step === 1"
                  :disabled="!stepper.step1"
                  class="mr-3"
              >
                <v-icon class="mr-1">mdi-chevron-right</v-icon>
                Next
              </v-btn>

              <v-btn
                  class="mr-2"
                  color="error"
                  @click="step=1"
                  v-if="step === 2"
              >
                <v-icon class="mr-1">mdi-chevron-left</v-icon>
                Back
              </v-btn>
              <v-btn
                  class="mr-3"
                  color="secondary"
                  @click="step = 3"
                  :disabled="!stepper.step2"
                  v-if="step === 2"
              >
                <v-icon class="mr-1">mdi-chevron-right</v-icon>
                Next
              </v-btn>

              <v-btn
                  color="error"
                  @click="step=2"
                  v-if="step === 3"
                  class="mr-2"
              >
                <v-icon class="mr-1">mdi-chevron-left</v-icon>
                Back
              </v-btn>
              <v-btn
                  class="mr-3"
                  :disabled="!stepper.step1 || !stepper.step2 || !stepper.step3"
                  color="success"
                  v-if="step ===3 && canAccess({route: '/organization/users/:id', method: 'patch'})"
                  @click="addUser">
                <v-icon class="mr-1">mdi-content-save</v-icon>
                Save
              </v-btn>

            </v-col>
          </v-row>
          <v-row v-else-if="!loading" class="mt-5">
            <v-alert type="warning" outlined border="left" class="mt-5 mx-auto py-3" prominent>
              <h2>Oops!</h2>
              <p>
                It appears you have no Organizations Created.<br/>
                Therefore you cannot create any User Accounts.
              </p>
              <p>
                Head to the <b>Organizations</b> Module from the top right User Menu.<br/>
                Create your organization, then come back to this section and you will be able to create user accounts.
              </p>
              <v-btn color="secondary" class="mx-auto float-left" small elevation="3" @click="openDocumentation">
                <v-icon small color="white" class="mr-1">mdi-information</v-icon>
                Read the Docks
              </v-btn>
              <v-btn color="primary" class="mx-auto float-right" small elevation="3" @click="goToGroups">
                <v-icon small color="white" class="mr-1">mdi-account-group</v-icon>
                open Organizations
              </v-btn>
            </v-alert>
          </v-row>
        </base-material-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import usersMixins from "./components/mixins";
import globalMixins from "../../../../mixins/globalMixins";
import fieldsMixins from "./components/fieldAccess";
import UserAccountMultitenancy from "@/views/internal/pages/users/components/multitenancy";
import UserAccountSecurity from "@/views/internal/pages/users/components/security";

export default {
  name: 'UsersAdd',

  mixins: [globalMixins, usersMixins, fieldsMixins],

  components: {
    UserAccountMultitenancy,
    UserAccountSecurity
  },

  props: {
    envSelected: {
      type: Object
    }
  },

  data: () => ({
    fieldAccess: {},
    step: 1,
    stepper: {
      step1: false,
      step2: false,
      step3: false
    },
    loading: true,
    user: {
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      username: '',
      password: '',
      confirmation: '',
      tenants: [],
      groups: [],
      auto_registration_flow: '',
      security: {
        throttling: {}
      },
      profile: {}
    },
    organizations: [],
    groups: [],
    strategies: [],
    tenants: []
  }),

  async created() {
    this.initialize()
  },

  watch: {
    "user": {
      handler: function (to, from) {
        this.stepper.step1 = false;
        this.stepper.step2 = false;
        this.stepper.step3 = false;

        if (
            (to.firstName && to.firstName.trim() !== '') &&
            (to.lastName && to.lastName.trim() !== '') &&
            (to.email && to.email.trim() !== '' && this.validEmail(to.email)) &&
            (to.company && to.company.trim() !== '') &&
            (to.password && to.password.trim() !== '' && this.validPassword(to.password) && to.password === to.confirmation)
        ) {
          this.stepper.step1 = true;
        }

        if (this.stepper.step1 && to.groups.length > 0) {
          this.stepper.step2 = true;
        }

        if (this.stepper.step2 && to.tenants.length > 0) {
          this.stepper.step3 = true;
        }
      },
      deep: true
    }
  },

  methods: {

    goToGroups() {
      this.$router.push({name: 'Accounts', params: {envSelected: this.envSelected}})
    },

    openDocumentation() {
      window.open(this.$helpLinks.users);
    },

    async initialize() {
      this.fieldAccess = this.userCreateAccess();

      const groups = await this.getSendData({
        'url': '/consoleapi/organization/groups',
        'method': 'get',
        'fields': ['name', 'code']
      });

      const tenants = await this.getSendData({
        'url': `/consoleapi/environments/${this.envSelected.value}/default/tenants`,
        'method': 'get',
        // 'fields': ['name', 'code', 'env', 'name'],
        params: {
          noEnv: true
        }
      });

      // arw is optional
      const arw = await this.getSendData({
        'url': `/consoleapi/environments/${this.envSelected.value}/arw/custom-entries`,
        'method': 'get'
      });

      this.arw = arw.items || [];
      this.groups = groups.items;
      this.tenants = tenants.items;

      const organizations = await this.getSendData({
        'url': `/consoleapi/organization/accounts`,
        'method': 'get',
        'params': {
          'pagination': false,
          'fields': ['name', 'groups', 'tenants', 'security']
        }

      });

      this.organizations = organizations.items || [];
      this.loading = false;
    },

    setSecurityAndTenants() {
      const _self = this;
      _self.user.groups = [];

      this.organizations.forEach((oneOrg) => {
        if (oneOrg.id === _self.user.company) {

          //wrap the groups
          _self.groups.forEach((oneGroup) => {
            if (oneOrg.groups.includes(oneGroup.code)) {
              _self.user.groups.push(_self._lodash.cloneDeep(oneGroup));
            }
          });

          _self.user.groups = oneOrg.groups;
          //wrap the tenants
          for (let i = _self.tenants.length - 1; i >= 0; i--) {
            let found = false;
            oneOrg.tenants.forEach((orgTenant) => {
              if (orgTenant.code === _self.tenants[i].code
                  && orgTenant.id === _self.tenants[i].id) {

                let oneOrgTenant = _self._lodash.cloneDeep(orgTenant);
                oneOrgTenant.name = _self.tenants[i].name;
                oneOrgTenant.env = _self.tenants[i].env;
                oneOrgTenant.description = _self.tenants[i].description;
                _self.user.tenants.push(oneOrgTenant);
                found = true;
              }
            });
            if (found) {
              _self.tenants.splice(i, 1);
            }
          }

          //wrap the security throttling strategies
          _self.strategies = [];
          let strategies = [];
          // TODO: check
          if (oneOrg.security.throttling) {
            _self.user.security.throttling = _self._lodash.cloneDeep(oneOrg.security.throttling);
            _self.user.security.throttling.strategies = strategies;
          }

          if (_self.user.security && _self.user.security.throttling && _self.user.security.throttling) {
            for (let i in _self.user.security.throttling) {
              if (!['publicAPIStrategy', 'privateAPIStrategy', 'strategies'].includes(i)) {
                let strategy = _self.user.security.throttling[i];
                strategy.name = i;
                _self.user.security.throttling.strategies.push(strategy);
                delete _self.user.security.throttling[i]
              }
            }
            _self.strategies = _self._lodash.cloneDeep(_self.user.security.throttling.strategies);
          }

          //force reload components props
          _self.$nextTick(() => {
            if (Object.hasOwnProperty.call(_self.$refs.securityWrapper, 'initialize')) {
              this.$refs.securityWrapper.initialize();
            }

            if (Object.hasOwnProperty.call(_self.$refs.multitenancyWrapper, 'initialize')) {
              this.$refs.multitenancyWrapper.initialize();
            }
          });
        }
      });
    },

    updateThrottlingStrategies(newList) {
      this.user.security.throttling.strategies = newList;
    },

    addUser() {
      const self = this;

      let newUser = this._lodash.cloneDeep(this.user);
      newUser.username = newUser.email;
      newUser.account = newUser.company;
      delete newUser.company;

      //validate the payload before invoking the API
      if (!this.validateUserRecord(newUser, true)) {
        return false;
      }
      let organization = {};
      this.organizations.forEach((oneOrg) => {
        if (oneOrg.id === newUser.account) {
          organization = oneOrg;
        }
      });

      newUser = this.cleanUpUserOrganization(newUser, organization);
      delete newUser.confirmation;

      let apiOptions = {
        url: `/consoleapi/organization/users`,
        method: "put",
        params: newUser
      };

      self.getSendData(apiOptions).then((response) => {
        self.pushMessage({
          type: 'success',
          title: `User Created`,
          text: `New User ${self.user.firstName} ${self.user.lastName} has been created!.`
        });
        self.scrollToTop();

        setTimeout(() => {
          self.$router.push({name: 'Users', params: {envCode: self.envSelected.value}});
        }, 2000);

      });
    }
  }
}
</script>

<style scoped>
.routerLink {
  text-decoration: none;
}

</style>
